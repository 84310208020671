/*
  https://css-tricks.com/snippets/css/complete-guide-grid/#prop-grid-template-columns-rows
*/

.site-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Header {
  background-color: #4267B2;
  color: white;
  position: sticky;
  top: 0px;
  margin-bottom: 1rem;
  z-index: 1;
}

.Content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 95%;
  margin: auto;
}

.Footer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 0.5rem;
}

.ant-layout-header {
  padding-inline: 1rem;
}

.LoginPage, .RegisterPage {
  width: 50%;
  @media only screen and (max-width: 600px) {
    width: 90%;
  }
}

.ant-row {
  width: 100% !important;
  margin-bottom: 1rem;
}

.Header a { color: white}

.Header .ant-btn {
  color: rgb(230,230,230);
}

.Header .ant-btn:hover {
  background-color: #898F9C;
  color: white;;
  border-color: orange;
}

.Header .ant-btn:hover svg {
  color: white;
}


.Header .ant-menu-light, 
.Header .ant-menu-light svg,
Header .ant-menu-submenu-arrow {
  background: none;
  color: white !important;
}