.listings .Content {}

.listings .Content .container {
    /* width: 100%; */
    display: flex;
    align-items: center;
    width: 95%;
    margin: auto;
    
}
.listings .container .ant-row {
    justify-content: space-around;
}

